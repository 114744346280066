import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VeeValidate from 'vee-validate';
import { Validator } from "vee-validate";
Vue.use(VeeValidate);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import { createPopper } from '@popperjs/core';
Vue.use(createPopper);

/* vue RUM */
import { ApmVuePlugin } from '@elastic/apm-rum-vue'

const configureRum = () => {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: 'admin-usuarios-rum',
      serverUrl: store.getters.apm.server,
      environment: store.getters.app.env,
    }
  })
}

//Plugins
import './../node_modules/jquery/dist/jquery.min.js'
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.min.js'

//datePicker
import datePicker from 'vue-bootstrap-datetimepicker';
import './../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'  
Vue.use(datePicker);

//import './assets/vendors/jquery.easy-pie-chart/dist/jquery.easypiechart.min.js'

//Select
import './assets/vendors/bootstrap-select/dist/js/bootstrap-select.min'
import './assets/vendors/bootstrap-select/dist/css/bootstrap-select.min.css'

import swal from './../node_modules/sweetalert2/dist/sweetalert2.min.css'
import './../node_modules/animate.css/animate.min.css'
//import './../node_modules/jquery.nicescroll/dist/jquery.nicescroll.min.js'

//Fonts
import './../node_modules/roboto-fontface/css/roboto/roboto-fontface.css'
import './../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css'

//Custom Style
import '../src/assets/scss/my-listview/my-listview.scss'
import '../src/assets/scss/my-form/my-form.scss'
import '../src/assets/scss/my-modal/my-modal.scss'
import '../src/assets/scss/my-app/my-app.scss'
import '../src/assets/scss/my-view-block/my-view-block.scss'
import '../src/assets/scss/my-menu/my-menu.scss'
import '../src/assets/css/app-core.css'
import '../src/assets/css/app-ecart.css'
import  '../src/assets/css/spinner/spinkit.css'

//JS
import './assets/js/app-core.js'
import UncLoader from '../src/components/utils/UncLoader.vue'

// TODO: cargar el valor de invalidDomains desde variables de entorno
const invalidDomains = 'mi'

//Custom Rules
Validator.extend('isNotYuliEmail', {
  getMessage: (field) => `El Correo Electrónico no puede ser '@unc.edu.ar ni @mi.unc.edu.ar'.`,
  validate: (value) => {
    let domains = invalidDomains.split(',')
    domains = domains.filter(e => !e.includes('.')).map(e => `${e}.`)
    return !new RegExp(`.*@((${domains.join('|')}))?unc.edu.ar$`).test(value)
  }
});

Validator.extend('isYuliEmail', {
  getMessage: (field) => `El Correo Electrónico debe ser '@unc.edu.ar o @mi.unc.edu.ar'.`,
  validate: (value) => {
    if ( /^(?!.*@unc\.edu\.ar).*$/.test(value) && /^(?!.*@mi\.unc\.edu\.ar).*$/.test(value) ) {
      return false;
    } else {
      return true;
    }
  }
});

Validator.extend('dni', {
  getMessage: (field) => 'El campo no puede contener caracteres especiales, excepto guiones en posiciones intermedias.',
  validate: (value) => {
    return /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/.test(value);
  }
})

Validator.extend('cuilFormat', {
  getMessage: (field) => 'El CUIL ingresado debe contener 11 números, sin guiones ni letras.',
  validate: (cuil) => cuil.length === 11
})

Validator.extend('cuilNumber', {
  getMessage: (field) => 'El CUIL debe ser válido.',
  validate: (cuil) => {
    cuil = cuil.replace(new RegExp('-', 'g'), '');
    let acum = 0;
    let digits = cuil.split('');
    let lastDigit = digits.pop();

    for(let i = 0; i < digits.length; i++) {
      acum += digits[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acum % 11);
    if(verif == 11) {
        verif = 0;
    } else if(verif == 10) {
        verif = 9;
    }
    return lastDigit == verif;
  }
})

const dictionary = {
  en: {
      messages: {
          required: (field) => 'El campo es requerido.',
          email: (field) => 'El campo debe ser un correo electrónico válido.',
          is: (field) => 'Los campos no coinciden.',
          alpha: (field) => `El campo debe contener solo caracteres alfabéticos.`,
          alpha_spaces: (field) => `El campo debe contener solo caracteres alfabéticos y espacios.`,
          numeric: (field) => `El campo debe contener solo caracteres numéricos.`,
          min: (field, params) => `El campo debe contener al menos ${params[0]} caracteres.`,
          max: (field, params) => `El campo no debe contener más de ${params[0]} caracteres.`,
          between: (field, params) => `El campo debe estar entre los números ${params[0]} y ${params[1]} inclusive.`,
      }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);

Vue.component('unc-loader',UncLoader)

Vue.config.productionTip = false

Vue.filter('toTitleCase', function (value) {
  let newValue = '';
  try {
      if (value) {
        newValue = value.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
  } catch (error) {
    return value;
  }
  return newValue;
});

Vue.filter('toIdentityType', (identityType) => {
  const identityMap = {
    'WORLD_PASS': 'PASAPORTE',
  };
  return identityMap[identityType] ?? identityType;
});

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    store.watch(
      (state) => state.config.apm,
      (newValue) => {
        if (newValue) {
          configureRum();
        }
      }
    );
  }
}).$mount('#app')
