<template>
  <div id="app">
    <div v-if='isLoading' class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-else>

      <header id="header" v-if="!forceLogin">
        <ul class="header-inner">
          <li id="menu-trigger" data-trigger="#sidebar" v-if="enabled">
            <div class="line-wrap">
              <div class="line top"></div>
              <div class="line center"></div>
              <div class="line bottom"></div>
            </div>
          </li>

          <li class="logo hidden-xs">
            <a href="index.html">GESTIÓN DE USUARIO UNC</a>
          </li>

          <li class="pull-right" v-if="enabled">
            <ul class="top-menu">
              <li class="dropdown">
                <a data-toggle="dropdown" class="tm-profile" href></a>
                <ul class="dropdown-menu dm-icon pull-right">
                  <!-- <li><a href="/perfil" @click.prevent="$router.push({ path: '/perfil' })">Mi Perfil</a></li>
                  <li><a href="/ayuda" @click.prevent="$router.push({ path: '/ayuda' })">Ayuda</a></li>-->
                  <li>
                    <a href="/cerrar-sesion" @click.prevent="cerrarSesion()">Cerrar Sesi&oacute;n</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </header>

      <section id="main">
        <aside id="sidebar" v-if="enabled">
          <div class="sidebar-inner c-overflow">
            <div class="profile-menu">
              <a href>
                <div class="profile-pic"></div>
                <div class="profile-info"></div>
              </a>
            </div>

            <ul class="main-menu" id="main-menu">
              <template v-for="item in dataMenu">
                <li v-if="item.route=='submenu'" class="sub-menu">
                  <a href :class="item.class">
                    <i :class="item.icon"></i>
                    {{ item.title }}
                  </a>
                  <ul>
                    <li v-for="subitem in item.submenu">
                      <a href @click.prevent="goRoute(subitem)">
                        <i :class="subitem.icon"></i>
                        {{ subitem.title }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li v-else>
                  <a href @click.prevent="goRoute(item)" :class="item.class">
                    <i :class="item.icon"></i>
                    {{ item.title }}
                  </a>
                </li>
              </template>

              <li>
                <a href @click.prevent="cerrarSesion()">
                  <i class="zmdi zmdi-power"></i>Cerrar Sesión
                </a>
              </li>
            </ul>
          </div>
        </aside>
        <!--/.sidebar-->

        <section id="content">
          <div id="main-body" class="container">
            <router-view></router-view>
          </div>
        </section>
        <!--/.content-->
      </section>
    </div> <!--ELSE-->
    <!--/.main-->

    <footer id="footer">
      <img src="./assets/img/unc-pi-logo.png" alt />
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      dataMenu: ""
    };
  },
  computed: {
    ...mapState(['isLoading','isInitStatus', 'permissionsView']),
    ...mapGetters([
        'currentUser',
        'forceLogin',
        'validUser',
        'memberTypes',
    ]),
      enabled () {
        return this.validUser;
      },
  },
  watch: {
    currentUser(newVal) {
      if (newVal) {
        this.hasPermissions('CHANGE_PERMISSIONS');
        this.getMenu();
      }
    },
    permissionsView(newVal) {
      this.getMenu();
    }
  },
  created() {
    this.$store.dispatch("InitStatus");
    if (this.currentUser) {
      this.hasPermissions('CHANGE_PERMISSIONS');
      this.getMenu();
    }
  },
  methods: {
    ...mapActions(['setPermissionsView']),
    hasPermissions(permission) {
      const uncOu = this.currentUser.adminOrgUnits.find(ou => ou.orgUnit.code === '000');
      this.setPermissionsView(uncOu ? uncOu.permissions?.includes(permission) : false);
    },
    getMenu() {
      let self = this;
      
      self.dataMenu = [
        {
          title: "Gestión de Usuario UNC",
          icon: "zmdi zmdi-accounts-list-alt",
          route: "usuarios",
          submenu: []
        },
        {
          title: "Mi Dependencia",
          icon: "zmdi zmdi-balance",
          route: "midependencia",
          submenu: []
        }
      ];

      if(this.permissionsView) {
        self.dataMenu.push(
          {
            title: "Gestión de Permisos",
            icon: "zmdi zmdi-shield-security",
            route: "permisos",
            submenu: []
          }
        );
      } else {
        const filteredMenu = self.dataMenu.filter(menu => menu.route !== 'permisos');
        self.dataMenu = filteredMenu;
      }
    },
    goRoute(menuOption) {
      switch (menuOption.route) {
        case "logout":
          this.cerrarSesion();
          break;
        default:
          this.$router.push(menuOption.route);
          break;
      }
    },
    cerrarSesion() {
      window.location = "/logout";
    }
  }
};
</script>

<style lang="scss">
#sidebar .profile-menu > a {
  display: block;
  height: 129px;
  margin-bottom: 5px;
  width: 100%;
  background: url(./assets/img/2019-PI-INFORMATICA-IMAGEN-2048x1642-12.png) left
    top no-repeat;
  background-size: 100%;
}
#footer img {
    filter: invert(70%) sepia(1%) saturate(0%) hue-rotate(199deg) brightness(91%) contrast(82%);
    max-height: 60px;
    text-align: center;
    color: #a2a2a2;

  }
select {
  box-shadow: none !important;
}
// https://github.com/sweetalert2/sweetalert2/issues/855
.swal2-popup {
    font-size: 1.5rem !important;
  }
</style>
