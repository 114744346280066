<template>
    <div>
      <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
      </div>
  
      <div v-else-if="showForm">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <admin-menu @eventToParent="eventFromChild"></admin-menu>
          </div>
        </div>
      </div>
  
      <div v-else class="row">
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header ch-alt text-center">
                  <h2>Criterios de Búsqueda</h2>
                </div>
                <div class="card-body card-padding">
                  <form role="form">
                    <div class="form-group fg-line">
                      <label>Nombres o Apellidos:</label>
                      <input
                        type="text"
                        v-model="query.filter.byFullname"
                        class="form-control input-sm"
                        placeholder
                      />
                    </div>
                    <div class="form-group fg-line">
                      <label>Nombre de Usuario:</label>
                      <input
                        type="text"
                        v-model="query.filter.byUserName"
                        class="form-control input-sm"
                        placeholder
                      />
                    </div>
                    <div class="form-group fg-line m-b-0">
                      <label>Número de Documento:</label>
                      <input
                        type="text"
                        v-model="query.filter.byIdentityNumber"
                        class="form-control input-sm"
                        placeholder="Número de DNI, CUIL..."
                      />  
                    </div>
                    <div class="form-group">
                        <input type="checkbox" id="checkbox" v-model="query.filter.byIdentityNumberExactSearch">
                        <label for="checkbox">&nbsp;&nbsp;&nbsp;Búsqueda exacta</label>
                    </div>                    
                    <div class="form-group fg-line">
                    <label>Dependencia:</label>
                        <div class="select">
                        <select v-model="query.filter.byOrgUnit" class="form-control tag-select">
                            <option v-for="item of optionsOrgUnits" :value="item">{{ item.name }}</option>
                        </select>
                        </div>
                    </div>
                    <button
                      @click.prevent="aplicarFiltros()"
                      type="submit"
                      class="btn btn-primary btn-block m-t-10"
                    >
                      <i class="zmdi zmdi-search"></i> Buscar
                    </button>
                    <button
                      @click.prevent="limpiarFiltros()"
                      type="submit"
                      class="btn btn-secundary btn-block m-t-10"
                    >
                      <i class="zmdi zmdi-format-clear"></i> Borrar Filtros
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-md-9 col-sm-12 col-xs-12">
          <div v-if="welcome && !isLoading" class="card">
            <div class="card-body">
              <div class="text-center">
                <img width="76%" src="../../assets/img/src_assets_img_fondo_unc_5.svg" />
              </div>
            </div>
          </div>
          <div v-else class="card">
            <div class="card-header bgm-gray">
              <h2>{{ listTitle }}</h2>
              <h2><small>{{ resultsParcialCount }} de {{ resultsTotalCount }} resultados encontrados.</small></h2>
            </div>
  
            <div class="card-body">
              <div v-if="listItems.length === 0 && !isLoading" class="jumbotron text-center">
                <h3>No se han encontrado registros.</h3>
                <p>Verifique los criterios de búsqueda y vuelva a intentarlo.</p>
              </div>
  
              <div v-else class="my-listview">
                <div class="row my-listview-item th hidden-xs">
                  <div class="col-md-4 col-sm-4 col-xs-12">Nombre y Apellido</div>
                  <div class="col-md-3 col-sm-3 col-xs-12">Documento</div>
                  <div class="col-md-2 col-sm-2 col-xs-12 ">Membresía</div>
                  <div class="col-md-1 col-sm-1 col-xs-12">Estado</div>
                  <div class="col-md-1 col-sm-1 col-xs-12"></div>
                </div>
  
                <div class="row my-listview-item" v-for="(item, index) in listItems" :key="item._id">
                  <div style="text-align:left!important" class="col-md-4 col-sm-2 col-xs-12 p-t-5">
                    <h1>{{ item.names }} {{ item.lastNames }}</h1>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12 p-t-5">
                    <div
                      v-for="identity in item.identities"
                    >{{ identity.identityType.replace('ARG_', '') }} {{ identity.identityNumber }}</div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-12 text-center p-t-5">
                    <div v-for="orgUnitRel in item.orgUnitRel" :key="orgUnitRel._id">
                      <h1> {{ getMembresy(orgUnitRel.typeMember) }}</h1>
                    </div>
                  </div>                
                  <div class="col-md-1 col-sm-1 col-xs-12 p-t-5 m-l-5">
                    <h1>{{ item.state ? item.state.comment.split('.',1)[0] :'Sin estado' }}</h1>
                  </div>
  
                  <div class="col-md-1 col-sm-1 col-xs-12 text-center p-b-1 m-l-10">
                    <ul class="my-actions">
                      <li>
                        <a href @click.prevent="editItem(item)">
                          <i class="zmdi zmdi-eye"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isLoading" class="loading">
            <unc-loader></unc-loader>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    swal,
    HTTPFront
  } from "../../share.js";
  import { mapGetters } from "vuex";
  import membershipTypes from '../../../utils/membership_types';
  import adminMenu from "./admin.menu.vue";
  
  export default {
    data() {
      return {
        listTitle: "LISTADO DE ADMINISTRADORES UNC",
        listItems: [],
        showForm: false,
        isInitializing: true,
        isLoading: false,
        isScrollActived: true,
        welcome: true,
        query: {
          order: "",
          page: 1,
          offset: 0,
          limit: 50,
          filter: {
            byFullname: "",
            byUserName: "",
            byIdentityNumber: "",
            byOrgUnit: "",
            byIdentityNumberExactSearch: true,
          }
        },
        optionsOrgUnits: [],
        optionsMembresia: membershipTypes,
        resultsParcialCount: 0,
        resultsTotalCount: 0
      };
    },
    computed: {
      ...mapGetters(['currentUser', 'orgUnitsByAdmin']),
    },
    components: {
        adminMenu,
    },
    mounted() {
      this.scroll();
    },
    created(){
      if(!this.hasPermissions('CHANGE_PERMISSIONS')) {
        this.$router.push('/');
      }
      this.getData();
    },
    methods: {
      hasPermissions(permission) {
        // INFO: Para poder acceder la persona tiene que tener el permiso en la dependencia UNC
        const uncOu = this.currentUser.adminOrgUnits.find(ou => ou.orgUnit.code === '000');
        return uncOu ? uncOu.permissions?.includes(permission) : false;
      },
      async getData() {
        let self = this;
        self.isLoading = true;
        self.isInitializing = true;
        const orgUnitsData = await self.getOrgUnits();
        self.optionsOrgUnits = orgUnitsData;
        let orgUnits = [];
        self.optionsOrgUnits.forEach(org => {
            orgUnits.push(org);
            orgUnits.push(
                ...org.orgUnitRel
                .map(ouRel => ouRel.orgUnitChild)
                .filter(ou => !orgUnits.find(e => e.code == ou.code))
            );
        });
        orgUnits.sort((a, b) => a.code > b.code ? 1 : b.code > a.code ? -1 : 0);
        self.optionsOrgUnits = orgUnits;
        self.optionsOrgUnits[0].name ? self.query.filter.byOrgUnit = self.optionsOrgUnits[0] : '';
  
        self.aplicarFiltros();
  
        self.$store.commit("setOrgUnitsByAdmin", self.optionsOrgUnits);
  
        self.isLoading = false;
        self.isInitializing = false;
      },
      scroll() {
        window.onscroll = () => {
          if (!this.welcome) {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            if (bottomOfWindow && this.isScrollActived) {
              this.getPeople(false);
            }
          }
        };
      },
      getMembresy(typeMember) {
        let membresy = this.optionsMembresia.find(option => { return option.typeMember === typeMember });
        if (membresy) {
            return membresy.name;
        }
      },    
      getPeople(resetPagination) {
        let self = this;
        this.welcome = false;
        this.isLoading = true;
  
        if (resetPagination) {
          this.listItems = [];
          this.query.page = 1;
        } else {
          this.query.page++;
        }
  
        self.getAdmins();
        
      },
      getAdmins() {
        let self = this;
        HTTPFront.post("/adminsByQuery", { query: this.query })
          .then(respuesta => {
            if (respuesta.status === 200) {
              if (respuesta.data.result.length > 0) {
                self.listItems = self.listItems.concat(...respuesta.data.result);
                self.resultsParcialCount = self.listItems.length
                self.resultsTotalCount = respuesta.data.totalCount
              }
              if (
                respuesta.data.result.length == 0 ||
                respuesta.data.result.length < self.query.limit
              ) {
                this.isScrollActived = false;
              } else {
                this.isScrollActived = true;
              }
            } else {
              swal.fire({
                title: "Error: " + respuesta.status,
                text: respuesta.statusText,
                icon: "error"
              });
            }
          })
          .catch(error => {
            let status = error.response.status;
            let message = error.response && error.response.data || 'Servicio temporalmente no disponible';
              switch (status) {
                case 400:
                  swal.fire({title: 'Faltan filtros', text: 'Debe ingresar algún filtro para poder realizar la búsqueda.', icon: 'warning' });
                  break;
                case 401:
                  swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                  break;
                case 409:
                  swal.fire({title: 'Algo salió mal', text: 'Algún dato parece tener un error.', icon: 'warning' });
                  break;
                case 500:
                  swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                  break;
                default:
                  swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                  break;
              }
          })
          .finally(() => {
            this.isLoading = false;
            this.isInitializing = false;
          });      
      },
      async getOrgUnits() {
        const response = await HTTPFront.get("/orgUnitsTree/" + this.currentUser.id);
        this.$store.commit("setOrgUnitsByAdmin", response.data);
        return response.data;
      },
      aplicarFiltros() {
        this.resultsParcialCount= 0
        this.resultsTotalCount= 0
        this.getPeople(true);
      },
      limpiarFiltros(){
        this.query.filter.byFullname = '';
        this.query.filter.byIdentityNumber = '';
        this.query.filter.byUserName = '';
        this.query.filter.byIdentityNumberExactSearch = true;
        this.welcome = true;
      },
      editItem(item) {
        Object.assign(item, {
          operation: "editItem",
        });
        this.$store.commit("setPerson", item);
        this.showForm = true;
      },
      eventFromChild(action = "", params = null) {
        switch (action) {
          case "canceled":
            this.getPeople(true);
            this.showForm = false;
            break;
  
          case "saved":
            this.getPeople(true);
            this.showForm = false;
            break;
        }
      }
    }
  };
  </script>
  
  <style lang="scss">
  
  .vcenter {
      display: inline-block;
      vertical-align: middle;
      float: none;
  }
  
  </style>