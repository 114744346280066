<template>
<div>
    <div class="card animated fadeIn">
        <div class="card-header-pending" :class="this.person.state.code === 'A' ? 'bgm-green' : 'bgm-gray'">
            <h2> {{titulo}} </h2>
            <span>Los administradores deberán verificar que los datos personales estén correctos y que la persona tenga relación con la UNC, mediante la solicitud de <b>Constancia de CUIL</b>, accediendo a los sistemas <b>Guaraní</b>, <b>Mapuche</b>, <b>Micuré</b>, etc. Según el Rol correspondiente, se podría solicitar la Resolución de designación.</span>
            <!-- <div>
                <button v-if="isEditable" @click.prevent="editItem()" class="btn bgm-blue btn-float waves-effect"><i class="zmdi zmdi-edit"></i></button>
            </div> -->
        </div>

        <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
            <unc-loader></unc-loader>
        </div>
        <div v-else class="card-body">
            <div class="my-view-block-pending" style="margin-top: 10px;!important">
                <form @submit.prevent="validateBeforeSubmit">
                    <ul>
                        <li>
                            <label>Nombres y Apellido</label><br>
                            {{ item.names }} {{ item.lastNames }}
                        </li>

                        <li v-for="identity in item.identities" :key="identity.identityType.code">
                            <label>{{ identity.identityType.replace('ARG_', '') | toIdentityType }}</label><br>
                            {{ identity.identityNumber }}
                        </li>

                        <li>
                            <label>Correo Electrónico de Recuperación</label><br>
                            <span v-for="(email,index) in item.emails" :key="email.mail">
                                <span v-if="index!='nextMainEmail'">{{ email.mail }} <br></span>
                            </span>
                        </li>

                        <li>
                            <label>Nombre de Usuario</label><br>
                                {{ item.users?.length > 0 ? item.users[0]?.userName : 'Sin Usuario UNC' }}
                            </li>

                            <li>
                                <label>Estado</label><br>
                                <small>
                                    <span :class="this.person.state?.code === 'A' ? 'badge bgm-green' : 'badge bgm-red'">{{ item.estado?.split('.',1)[0] }}
                                    </span>
                                </small><br>
                                <span>
                                    Pendiente de verificación
                                </span>
                                <a
                                    style="font-size: 20px;margin-left: 5px; pa"
                                    data-toggle="popover-2"
                                    data-placement="right"
                                    tabindex="0"
                                    data-original-title="Aclaración">
                                    <i class="zmdi zmdi-pin-help"></i>
                                </a>
                            </li>

                            <li>
                                <div :class="{'has-error': errors.has('uncProfile')}">
                                    <div class="fg-line">
                                        <label>Roles</label>
                                    </div>
                                    <div v-for="(profile, index) of roleOptions" :key="profile.code" class="radio" :class="[ index !== roleOptions.length - 1 ? 'm-b-30': 'm-b-20' ]">
                                        <label>
                                            <input type="radio" name="uncProfile" v-model="item.uncProfile" :value="profile.code" v-validate="'required'"/>
                                            <i class="input-helper"></i>
                                            {{ profile.name }}
                                        </label>
                                    </div>
                                    <small v-if="errors.has('uncProfile')" class="help-block">Es requerido seleccionar al menos un rol</small>
                                </div>
                            </li>

                            <li>
                                <label>Fecha de Registro de Persona</label><br>
                                {{ item.personDate }}
                            </li>
                            <span class="badge" :class="item.personState?.code === 'A' ? 'bgm-green' : 'bgm-red'" >{{ item.personState?.name }}</span>

                            <li>
                                <div :class="{'has-error': errors.has('orgunit_id')}">
                                    <div class="fg-line">
                                    <label>Dependencia a vincular</label>
                                    <div class="select input-margin">
                                        <select
                                        v-model="orgUnitItem.orgunit_id"
                                        id="orgunit_id"
                                        name="orgunit_id"
                                        v-validate="'required'"
                                        class="form-control"
                                        >
                                        <option value>[Seleccione una opción]</option>
                                        <option
                                            v-for="item of orgUnitsTree"
                                            :value="item._id"
                                            :key="item._id"
                                        >{{ item.name}}</option>
                                        </select>
                                    </div>
                                    </div>
                                    <small v-if="errors.has('orgunit_id')" class="help-block">El campo es requerido</small>
                                </div>
                            </li>
                            <span><span>Esta persona será vinculada a la Dependencia con una membresía del tipo: Miembro</span></span> 

                    </ul>

                    <div class="text-right input-margin">
                        <button
                        @click="$emit('eventToParent','canceled', 'goSearch')"
                        type="button"
                        class="btn btn-link btn-sm m-t-10 waves-effect"
                        >Atrás</button>
                        <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect">Verificar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import { HTTPFront, swal } from "../../share.js";
    import { mapGetters } from "vuex";
    import membershipTypes from '../../../utils/membership_types';

export default {
    data() {
        return {
            titulo: 'VERIFICACIÓN DE DATOS',
            orgUnitItem: {
                orgunit_id: "",
                typeMember: ""
            },
            item: {},
            isEditable: false,
            orgUnitsTree: [],
            show: false,
            roleOptions: [],
            loadingAction: false,
            userSelectedUncProfile: '',
        }
    },
    computed: {
        ...mapGetters(["person", "currentUser", "uncProfiles"])
    },
    mounted() {
        this.roleOptions = this.uncProfiles;
        this.getData();
    },
    created() {
        this.item = this.person;
        this.item.uncProfile = this.person.profile.code;
        this.userSelectedUncProfile = this.item.uncProfile;

        /*Formateo de fecha de creación del registro de la persona*/
        let timestamp = this.item._id.toString().substring(0,8);
        let date = new Date( parseInt( timestamp, 16 ) * 1000 );
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        this.item.personDate = day +'/'+month+'/'+year

        this.isEditable = this.person.isEditable;
        this.item.estado = this.person.state ? this.person.state.comment : "Sin estado asociado";
        this.item.perfil = this.person.profile ? this.person.profile.name : "Sin rol asociado";
        this.completeStatePopOver(this.item.estado);
    },
    methods: {
    getData() {
        this.getOrgUnitsTree();
    },
    completeStatePopOver(estado) {
        $(function () {
            $('[data-toggle="popover-2"]').popover({
                content: `<span><span>${estado.split('.',2)[1]}</span></span>`,
                html : true,
                trigger: 'focus'
            })
        })

    },
    validateBeforeSubmit(e) {
      this.$validator
        .validateAll()
        .then(() => {
          if (!this.errors.any()) {
            this.save();
          } else {
            console.log("No Validated: ", JSON.stringify(this.errors.items));
          }
        })
        .catch(() => {
          console.log("Error Validated!");
        });
    },
    save() {
        this.loadingAction = true;

        let self = this;

      /*TODO: El valor de typeMember y nextCodeState deberían obtenerse desde un archivo utils*/
        const member = membershipTypes.find(el => { return el.name === 'Miembro' })

        let validPerson = {
            personId: self.item._id,
            orgUnitId: self.orgUnitItem.orgunit_id,
            typeMember: member.typeMember,
            adminId: self.currentUser.id,
            nextCodeState: 'V',
            uncProfile: self.item.uncProfile,
        };

        if (this.person.state.code === "PD") {
            HTTPFront.post("/validPersonInOrgUnit", validPerson)
            .then(respuesta => {
                if (respuesta.status === 200) {
                    swal.fire({ title: 'Éxito', html: `${this.person.names} ${this.person.lastNames} se ha verificado satisfactoriamente`, icon: "success" })
                    .then(() => { 
                        let self = this;
                        self.$emit('eventToParent','saved');
                    })
                } else {
                    swal.fire({ title: "Error: " + respuesta.status, text: respuesta.statusText, icon: "error" });
                }
            })
            .catch(error => {
                let status = error.response.status;
                let message = error.response && error.response.data || 'Servicio temporalmente no disponible';
                    switch (status) {
                        case 401:
                            swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                            break;
                        case 409:
                            swal.fire({title: 'Algo salió mal ', text: 'No se pudo verificar a la persona', icon: 'warning' });
                            break;
                        case 500:
                            swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                            break;
                        default:
                            swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                            break;
                    }
            })
            .finally(() => {
                this.isLoading = false;
                this.isInitializing = false;
                this.loadingAction = false;
            });
        } else {
            swal.fire({ title: "Advertencia", text: 'La persona a ser vinculada en esta dependencia no se encuentra en estado de ser verificada por un administrador.', icon: "warning" });
            this.loadingAction = false;
        }

    },   
    getOrgUnitsTree() {
      return HTTPFront.get("/orgUnitsTree/" + this.currentUser.id).then(respuesta => {
        this.orgUnitsTree = respuesta.data;
        let orgUnits = [];
        this.orgUnitsTree.forEach(org => {
          orgUnits.push(org);
          orgUnits.push(
            ...org.orgUnitRel
              .map(ouRel => ouRel.orgUnitChild)
              .filter(ou => !orgUnits.find(e => e.code == ou.code))
          );
        });
        orgUnits.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        this.orgUnitsTree = orgUnits;
      });
    }        
        /* editItem() {
            this.$emit('eventToParent','editItem')
        } */
    }
}
</script>

<style lang="scss" scoped>

    .my-listview-item {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }

</style>