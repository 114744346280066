<template>
<div>
    
    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else-if="showFormSettingEmail">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <usuario-settings-email-form :dataParent="dataForChild" @eventToParent="eventFromChild"></usuario-settings-email-form>
            </div>
        </div>
    </div>

    <div v-else-if="showFormSettingDomain">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <usuario-settings-domain-form :dataParent="dataForChild" @eventToParent="eventFromChild"></usuario-settings-domain-form>
            </div>
        </div>
    </div>

    <div v-else-if="showFormSettingIdentities">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <usuario-settings-identities-sublist :dataParent="dataForChild" @eventToParent="eventFromChild"></usuario-settings-identities-sublist>
            </div>
        </div>
    </div>

    <div v-else class="row animated fadeIn">
    
        <div class="col-md-12 col-sm-12 col-xs-12">

            <div class="card">
                <div class="card-header ch-alt bgm-green">
                    <h2>CONFIGURACIÓN</h2>
                </div>
                    
                <div class="card-body">
                    <div class="my-listview">
                        <a href="" @click.prevent="editItem('updateEmail')">
                            <div class="row my-listview-item" v-if="hasPermissions('CHANGE_RECOVERY_EMAIL')">
                                <div class="col-md-10 col-sm-10 col-xs-12">
                                    <h1>Correo Electrónico de Recuperación</h1>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 text-right">
                                    <ul class="actions">
                                        <li>
                                            <a><i class="zmdi zmdi-arrow-right"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <a href="" @click.prevent="editItem('updateDomain')">
                            <div class="row my-listview-item" v-if="hasPermissions('CHANGE_USERNAME')">
                                <div class="col-md-10 col-sm-10 col-xs-12">
                                    <h1>Cambio de Dominio</h1>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 text-right">
                                    <ul class="actions">
                                        <li>
                                            <a><i class="zmdi zmdi-arrow-right"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <a href="" @click.prevent="editItem('updateIdentities')">
                            <div class="row my-listview-item" v-if="hasPermissions('CHANGE_IDENTITIES')">
                                <div class="col-md-10 col-sm-10 col-xs-12">
                                    <h1>Gestión de Documentos de Identidad</h1>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 text-right">
                                    <ul class="actions">
                                        <li>
                                            <a><i class="zmdi zmdi-arrow-right"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import { HTTPFront, swal } from '../../share.js';
    import axios from 'axios';
    import { mapGetters } from 'vuex';
    import usuarioSettingsEmailForm from './usuario.settings.email.form';
    import usuarioSettingsDomainForm from './usuario.settings.domain.form.vue';
    import usuarioSettingsIdentitiesSublist from './usuario.settings.identities.sublist.vue';

    export default {
        data() {
            return {
                listTitle: 'DEPENDENCIAS VINCULADAS',
                listItems: [],
                showFormSettingEmail: false,
                showFormSettingDomain: false,
                showFormSettingIdentities: false,
                isInitializing: false,
                isEditable: true,
                isLoading: false,
                isScrollActived: true,
                item: '',
                query: {
                    order: 'titulo',
                    page: 0,
                    offset: 0,
                    limit: 25
                },
                dataForChild: {
                    idSelected: 0,
                    operation: '',
                    isEditable: true
                }
            }
        },
        computed: {
            ...mapGetters([
                'currentUser',
                'orgUnitsByUser',
                'memberTypes',
                'person',
            ]),
        },
        props: [
            'dataParent'
        ],
        components: {
            usuarioSettingsEmailForm,
            usuarioSettingsDomainForm,
            usuarioSettingsIdentitiesSublist,
        },
        mounted() {
            this.item = this.dataParent.itemSelected;
        },
        methods: {
            hasPermissions(permission) {
                const personOrgUnits = this.orgUnitsByUser?.map(el => el.orgUnit._id);
                const adminOrgUnits = this.currentUser.adminOrgUnits.filter(personInOu => (personInOu.typeMember === this.memberTypes.ADMIN) && (personInOu.permissions?.includes(permission))).map(personInOu => personInOu.orgUnit._id);
                return adminOrgUnits.some(adminOu => personOrgUnits.some(personOu => adminOu === personOu));
            },
            editItem(action) {

                this.hideForms();

                switch (action) {
                    case 'updateEmail':
                        this.dataForChild.idSelected = this.item._id;
                        this.dataForChild.operation = "editItem";
                        this.dataForChild.isEditable = true;
                        this.showFormSettingEmail = true;                               
                    break;

                    case 'updateDomain':
                        this.dataForChild.idSelected = this.item._id;
                        this.dataForChild.operation = "editItem";
                        this.dataForChild.isEditable = true;
                        this.showFormSettingDomain = true;                               
                    break;

                    case 'updateIdentities':
                        this.dataForChild.idSelected = this.item._id;
                        this.dataForChild.operation = "editItem";
                        this.dataForChild.isEditable = true;
                        this.showFormSettingIdentities = true;                               
                    break;
                }
            },
            eventFromChild(action = '', params = null){
                switch (action) {
                    case 'canceled':
                        this.hideForms();    
                    break;

                    case 'saved':
                        this.refreshPerson();
                        this.hideForms();
                    break;
                }
            },
            hideForms() {
                this.showFormSettingEmail = false;
                this.showFormSettingDomain = false;
                this.showFormSettingIdentities = false;
            },
            async refreshPerson() {
                try {
                    const response = await HTTPFront.get("/person/" + this.person._id);
                    this.$store.commit("setPerson", response.data);
                } catch (error) {
                    let status = error?.response && error?.response?.data?.code || error?.response?.status;
                    let message = error?.response && error?.response?.data?.message || 'Servicio temporalmente no disponible';
                    switch (status) {
                        case 401:
                            await swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                            break;
                        case 500:
                            await swal.fire({title: 'Algo salió mal', text: message, icon: 'warning' });
                            break;
                        default:
                            await swal.fire({title: 'Error al obtener información de la persona', text: message, icon: 'warning' });
                            break;
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
    
</style>