<template>
    <div>
      <div v-if="hasInitialized" class="loading">
        <unc-loader></unc-loader>
      </div>
      <div v-else>
        <div>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- ESCABEZAZO GRIS DE LA IZQUIERDA -->
              <div class="block-header">
                <h2>{{ item.names }} {{ item.lastNames }}</h2>
                <ul class="actions">
                  <li>
                    <a href @click.prevent="$emit('eventToParent','canceled')" title="Volver">
                      <i class="zmdi zmdi-close"></i>
                    </a>
                  </li>
                </ul>
              </div>
                <!-- CARD BLANCA DE LA IZQUIERDA -->
              <div class="card">
                <div class="card-body">
                </div>
                <div class="card-header text-center">
                  <h2>
                    {{ item.names }} {{ item.lastNames }}
                  </h2>
                </div>
                <div class="card-body">
                  <ul class="my-page-menu">
                    <li :class="currentView === 'usuario-view' ? 'active' : ''">
                      <a href @click.prevent="currentView='usuario-view'">
                        <i class="zmdi zmdi-account-circle"></i> Información General
                      </a>
                    </li>
                    <li :class="currentView ==='admin-permissions-list' ? 'active' : ''">
                      <a href @click.prevent="currentView='admin-permissions-list'">
                        <i class="zmdi zmdi-shield-security"></i> Permisos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
                <!-- BOTON VOLVER -->
                <button @click="$emit('eventToParent','canceled')" class="btn btn-primary btn-block btn-sm waves-effect">Volver a Búsqueda</button>
            </div>
          
          <div class="col-md-9 col-sm-12 col-xs-12">
            <component
              :is="currentView"
              :dataParent="dataForChild"
              @eventToParent="eventFromChild"
              keep-alive
            ></component>
          </div>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { HTTPFront } from "../../share.js";
  import { mapGetters } from "vuex";
  
  import usuarioView from "../usuarios/usuario.view.vue";
  import adminPermissionsList from "./admin.permissions.list.vue";
  
  export default {
    data() {
      return {
        currentView: "usuario-view",
        idSelected: "",
        operation: "",
        isInitializing: true,
        item: "",
        dataForChild: {
          passwordChangeHistory: [],
        },
      };
    },
    computed: {
      ...mapGetters([
        'person',
      ]),
      hasInitialized() {
        return this.isInitializing;
      },
    },
    components: {
      usuarioView,
      adminPermissionsList,
    },
    async mounted() {
      this.item = this.person;
  
      if(this.item?.users.length) {
        await this.fetchPasswordChangeHistory();
      }
      this.isInitializing = false;
    },
    methods: {
      async fetchPasswordChangeHistory() {
        const fetchedUsers = await HTTPFront.get("/people/" + this.item._id + '/users');
        this.dataForChild.passwordChangeHistory = fetchedUsers.data[0]?.passwordChangeHistory ?? [];
      },
      async eventFromChild(action = "", params = null) {
        switch (action) {
          case "canceled":
              break;
          case "saved":
              break;
        }
      },
    }
  };
  </script>
  
  <style lang="scss">
  </style>