//Axios: Peticiones HTTP via Ajax
import axios from 'axios';
// var config = require('/run/secrets/pinda_frontend.json')
//var config = require('../../../config/pinda_frontend.json')
import store from './store/index'

export const swal = require('sweetalert2');

const clientAjaxHeader = { 'x-client-ajax': 1 };

export const HttpYULI = axios.create({
  baseURL: '/yulibackend/',
  headers: clientAjaxHeader,

});

export const HttpYULI2 = axios.create({
  baseURL: '/yuli2backend/',
  headers: clientAjaxHeader,

});

export const HTTPFront = axios.create({
  baseURL: '/',
  headers: clientAjaxHeader,
});

export const HttpGEO = axios.create({
  baseURL: '/geoobjectbackend/',
  headers: clientAjaxHeader,
});

const manageExpiredToken = async error => {
  const status = error.response ? error.response.status : null;
  if (status === 418) {
      return store.dispatch('handleCannotGetUserError');
  }
  return Promise.reject(error);
};

const manageNetworkError = async error => {
  if (!error?.response || error?.response.data.message === 'ECONNREFUSED') {
      return store.dispatch('handleNetworkError');
  }
  return Promise.reject(error);
};

[HTTPFront, HttpYULI].forEach( httpClient => {
  httpClient.interceptors.response.use(response => response, manageExpiredToken);
  httpClient.interceptors.response.use(response => response, manageNetworkError);

});

