var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isInitializing)?_c('div',{staticClass:"loading"},[_c('unc-loader')],1):(_vm.showFormSettingEmail)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('usuario-settings-email-form',{attrs:{"dataParent":_vm.dataForChild},on:{"eventToParent":_vm.eventFromChild}})],1)])]):(_vm.showFormSettingDomain)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('usuario-settings-domain-form',{attrs:{"dataParent":_vm.dataForChild},on:{"eventToParent":_vm.eventFromChild}})],1)])]):(_vm.showFormSettingIdentities)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('usuario-settings-identities-sublist',{attrs:{"dataParent":_vm.dataForChild},on:{"eventToParent":_vm.eventFromChild}})],1)])]):_c('div',{staticClass:"row animated fadeIn"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"my-listview"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.editItem('updateEmail')}}},[(_vm.hasPermissions('CHANGE_RECOVERY_EMAIL'))?_c('div',{staticClass:"row my-listview-item"},[_vm._m(1),_vm._m(2)]):_vm._e()]),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.editItem('updateDomain')}}},[(_vm.hasPermissions('CHANGE_USERNAME'))?_c('div',{staticClass:"row my-listview-item"},[_vm._m(3),_vm._m(4)]):_vm._e()]),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.editItem('updateIdentities')}}},[(_vm.hasPermissions('CHANGE_IDENTITIES'))?_c('div',{staticClass:"row my-listview-item"},[_vm._m(5),_vm._m(6)]):_vm._e()])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header ch-alt bgm-green"},[_c('h2',[_vm._v("CONFIGURACIÓN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10 col-sm-10 col-xs-12"},[_c('h1',[_vm._v("Correo Electrónico de Recuperación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 col-sm-2 col-xs-12 text-right"},[_c('ul',{staticClass:"actions"},[_c('li',[_c('a',[_c('i',{staticClass:"zmdi zmdi-arrow-right"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10 col-sm-10 col-xs-12"},[_c('h1',[_vm._v("Cambio de Dominio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 col-sm-2 col-xs-12 text-right"},[_c('ul',{staticClass:"actions"},[_c('li',[_c('a',[_c('i',{staticClass:"zmdi zmdi-arrow-right"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10 col-sm-10 col-xs-12"},[_c('h1',[_vm._v("Gestión de Documentos de Identidad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 col-sm-2 col-xs-12 text-right"},[_c('ul',{staticClass:"actions"},[_c('li',[_c('a',[_c('i',{staticClass:"zmdi zmdi-arrow-right"})])])])])
}]

export { render, staticRenderFns }