import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import authService from '../auth/authService.js';

import { HTTPFront, swal } from './../share.js';

Vue.use(Vuex);

const state = {
  isLoading: true,
  isInitStatus: false,
  currentUser: null,
  person: null,
  orgUnitsByUser: [],
  orgUnitsByAdmin: [],
  authService: null,
  identityTypes: [],
  optionsCountries: [],
  memberTypes: {
    MEMBER: 0,
    REPRESENTATIVE: 1,
    ADMIN: 2,
  },
  uncProfiles: [],
  permissions: [],
  config: {
    fileSizeLimit: '',
    app: {},
    apm: {},
  },
  permissionsView: false,
};

export default new Vuex.Store({
  state: state,
  mutations: {
    InitStatus(state){
      state.isInitStatus = true;
      state.isLoading = false;
      state.authService = authService;
    },
    setLoading(state,value){
      state.isLoading = value;
    },
    setCurrentUser(state, user){
      state.currentUser = user;
    },
    setPerson(state, user){
      state.person = user;
    },
    setOrgUnitsByUser(state, orgUnits) {
      state.orgUnitsByUser = orgUnits;
    },
    setIdentityTypes(state, value){
      state.identityTypes = value;
    },
    setOptionsCountries(state, value){
      state.optionsCountries = value;
    },
    setOrgUnitsByAdmin(state, orgUnits) {
      state.orgUnitsByAdmin = orgUnits
    },
    setUncProfiles(state, profiles) {
      state.uncProfiles = profiles;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    logout(state){
      state.isInitStatus = false;
      state.currentUser = null;
    },
    setConfig(state, config){
      state.config = config;
    },
    updateCurrentUser(state, changes) {
      state.currentUser = { ...state.currentUser, changes };
    },
    setPermissionsView(state, status) {
      state.permissionsView = status;
    },
  },
  actions: {
    async getUncProfiles ({ commit, dispatch }) {
      const profiles = await HTTPFront.get('/uncProfiles');
      commit('setUncProfiles', profiles.data);
    },
    async fetchConfig ({ commit, dispatch }) {
      try {
        const config = await HTTPFront.get('/config');
        commit('setConfig', config.data);
      } catch (err) {
        throw err;
      }
    },
    setPermissionsView({ commit }, status) {
      commit('setPermissionsView', status);
    },
    updateCurrentUser({ commit }, changes) {
      commit('updateCurrentUser', changes);
    },
    setUserOrgUnits({ commit }, orgUnits) {
      commit('setOrgUnitsByUser', orgUnits)
    },
    setPermissions({ commit }, permissions) {
      commit('setPermissions', permissions);
    },
    async InitStatus ({ commit, dispatch }) {
      authService.$watch('loading', async loading => {
        if (!loading) {
          commit('InitStatus');
          if(authService.validUser) {
            try {
              await Promise.all([
                dispatch('fetchConfig'),
                dispatch('getUncProfiles'),
              ]);
            } catch (err) {
              commit('setLoading', true);
              swal.fire({
                title: 'Oops! Hubo un error',
                text: 'Se produjo un error mientras cargaba el sitio. Por favor recargue la página e intente nuevamente.',
                icon: 'warning',
                confirmButtonText: 'Recargar',
                allowOutsideClick: false,
              }).then(() => location.reload())
            }
          } else {
            if(authService?.error?.response?.status === 500) {
              swal.fire({
                title: 'Oops! Hubo un error',
                text: 'Se produjo un error mientras cargaba el sitio. Por favor recargue la página e intente nuevamente.',
                icon: 'warning',
                confirmButtonText: 'Recargar',
                allowOutsideClick: false,
              }).then(() => location.reload())
            }
          }
        }
        
      })
    },
    async handleCannotGetUserError ({ commit, dispatch }) {
      commit('setLoading', true);
      swal.fire({
          title: 'La sesión del usuario ha caducado',
          text: 'Se ha cerrado la sesión.',
          icon: 'warning',
          confirmButtonText: 'Ingresar al sistema',
          allowOutsideClick: false,
      }).then(() => dispatch('login'));
    },
    async handleNetworkError ({ commit, dispatch }) {
      commit('setLoading', true);
      await swal.fire({
        title: 'Oops! Hubo un error',
        text: 'Se produjo un error mientras cargaba el sitio. Por favor recargue la página e intente nuevamente.',
        icon: 'warning',
        confirmButtonText: 'Recargar',
        allowOutsideClick: false,
      }).then(() => location.reload())
    },
    async login ({ commit }, target) {
      commit('logout');
      let href = '/login';
      if (target) {
        href += `?t=${target}`;
      }
      window.location.href = href;
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout');
        resolve();
      })
    }
  },
  getters : {
    person: state => {
      return state.person;
    },
    orgUnitsByUser: state => {
      return state.orgUnitsByUser;
    },
    orgUnitsByAdmin: state => {
      return state.orgUnitsByAdmin;
    },
    currentUser: state => state.authService?.user,
    validUser: state => state.authService?.validUser,
    forceLogin: state => state.authService?.forceLogin,
    memberTypes: state => state.memberTypes,
    uncProfiles: state => state.uncProfiles,
    permissions: state => state.permissions,
    identityTypes: state => state.identityTypes,
    optionsCountries: state => state.optionsCountries,
    fileSizeLimit: state => state.config.fileSizeLimit,
    app: state => state.config.app,
    apm: state => state.config.apm,
    permissionsView: state => state.permissionsView,
},
  modules: {

  }
})