<template>
    <div>
    
        <div v-if="isInitializing" class="loading">
            <unc-loader></unc-loader>
        </div>
    
        <div v-else-if="showForm" class="animated fadeIn">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <usuario-settings-identities-form :dataParent="dataForChild" @eventToParent="eventFromChild"></usuario-settings-identities-form>
                </div>
            </div>
        </div>
    
        <div v-else class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                        <div class="card-header ch-alt">
                            <h2> GESTIÓN DE DOCUMENTOS DE IDENTIDAD</h2>
                            <button @click.prevent="addItem" class="btn bgm-red btn-float waves-effect" title="Agregar documento"><i class="zmdi zmdi-plus"></i></button>
                            <ul class="actions">
                                <li><a href="" @click.prevent="$emit('eventToParent','canceled')"><i class="zmdi zmdi-close"></i></a></li>
                            </ul>
                    </div>
    
                     <div class="card-body">
                        <div v-if="isLoading" class="loading">
                            <unc-loader></unc-loader>
                        </div>
                        <div v-else-if="personIdentities.length === 0" class="jumbotron text-center">
                            <h3>No se han encontrado documentos vinculados.</h3>
                        </div>
                        <div v-else class="my-listview">
                            <div class="row my-listview-item hidden-xs">
                                <div class="col-md-2 col-sm-2 col-xs-12"><b>País</b></div>
                                <div class="col-md-4 col-sm-4 col-xs-12"><b>Tipo de documento</b></div>
                                <div class="col-md-4 col-sm-4 col-xs-12"><b>Número de documento</b></div>
                            </div>
    
                            <div class="row my-listview-item" v-for="identity in personIdentities" :key="identity.identityNumber">
                                <div class="col-md-2 col-sm-2 col-xs-12">
                                    <h1>{{ identity.countryName }}</h1>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12">
                                    <h1>{{ identity.identityTypeShortName }}</h1>
                                    <small>{{ identity.identityTypeName }} </small>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12">
                                    <h1>{{identity.identityNumber}}</h1>
                                </div>
    
                                <div v-if="identity.countryName === 'Argentina'" class="col-md-2 col-sm-2 col-xs-12">
                                    <ul class="my-actions">
                                        <li><a href="" @click.prevent="editItem(identity)" title="Editar Documento"><i class="zmdi zmdi-edit"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>  
        </div>
    </div>
    </template>
    
    <script>
        import  { HttpGEO } from '../../share'
        import { mapGetters } from "vuex";
        import axios from 'axios'
        import usuarioSettingsIdentitiesForm from './usuario.settings.identities.form.vue';
    
        export default {
            data() {
                return {
                    allIdentityTypes: [],
                    showForm: false,
                    isInitializing: true,
                    isEditable: true,
                    isLoading: false,
                    item: '',
                    selectedIdentity: '',
                    dataForChild: {
                        idSelected: 0,
                        operation: '',
                        isEditable: true
                    },
                    optionsCountries: [],
                    personIdentities: [],
                }
            },
            computed: {
                ...mapGetters(["person"]),
            },        
            props: [
                'dataParent',
            ],
            components: {
                usuarioSettingsIdentitiesForm,
            },
            mounted() {
                this.item = this.person;
                this.isEditable = this.person.isEditable;
                this.getData();
            },
            methods: {
                getData() {
                    let self = this;
                    self.isLoading = true;

                    const personIdentityCountries = Array.from(new Set(this.person.identities.map(identity => identity.identityType.split('_')[0]))).filter(countryCode => countryCode !== 'WORLD');
                    
                    if (personIdentityCountries.length === 0) {
                        self.mapIdentities();
                        self.isLoading = false;
                        self.isInitializing = false;
                        return; 
                    }
                    const identityPromises = personIdentityCountries.map(countryCode => self.getIdentityTypes(countryCode));
                    const countryPromises = personIdentityCountries.map(countryCode => self.getCountry(countryCode));
                    axios.all([...identityPromises, ...countryPromises])
                    .then(axios.spread(function (...responses) {
                        const responseIdentityTypes = responses.slice(0, identityPromises.length);
                        const responseCountries = responses.slice(identityPromises.length);
                        self.allIdentityTypes = [...responseIdentityTypes.flatMap(response => response.data.results)];
                        self.optionsCountries = [...responseCountries.flatMap(response => response.data)];
                        self.$store.commit('setIdentityTypes', self.allIdentityTypes);
                        self.$store.commit('setOptionsCountries', self.optionsCountries);
                    })).then(() => {
                        self.mapIdentities();
                    }).finally(() => {
                        self.isLoading = false;
                        self.isInitializing = false;
                    });
                },
                mapIdentities() {
                    this.personIdentities = this.item.identities.map(personIdentity => {
                            const geobjectIdentity = this.allIdentityTypes.find(identity => identity.code === personIdentity.identityType);
                            const geobjectCountry = geobjectIdentity ? this.optionsCountries.find(country => country.code_iso_alfa3 === geobjectIdentity.code.split('_')[0]) : null;
                            if(personIdentity.identityType === 'WORLD_PASS') {
                                return {
                                    geobjectIdentity: {
                                        code: 'WORLD_PASS',
                                        short_name: 'PASS',
                                        name: 'Pasaporte',
                                    },
                                    countryName: 'Internacional',
                                    identityTypeShortName: 'PASS',
                                    identityTypeName: 'Pasaporte',
                                    identityNumber: personIdentity.identityNumber,
                                }
                            }
                            return {
                                geobjectIdentity,
                                countryName: geobjectCountry.name_iso,
                                identityTypeShortName: geobjectIdentity.short_name,
                                identityTypeName: geobjectIdentity.name,
                                identityNumber: personIdentity.identityNumber,
                            }
                        })
                },
                getCountry(code){
                    return HttpGEO.get(`countries/${code}`);
                },
                getIdentityTypes(countryCode){
                    return HttpGEO.get(`countries/${countryCode}/identity_types`);
                },
                addItem() {
                    this.dataForChild.operation = "addItem";
                    this.showForm = true;
                },
                editItem(identity) {
                    this.dataForChild.parentSelected = identity;
                    this.dataForChild.operation = 'editItem';
                    this.showForm = true;
                },
                eventFromChild(action = '', params = null){
                    switch (action) {
                        case 'canceled':
                            this.getData();
                            this.showForm = false;
                        break;
    
                        case 'saved':
                            this.showForm = false;
                            this.getData();
                        break;
                    }
                }
            }
        }
    </script>
    
    <style lang="scss">
        
    </style>