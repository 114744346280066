<template>
    <div>
      <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
      </div>
      <div v-show="!isInitializing" class="card animated fadeIn">
        <div class="card-header ch-alt">
          <h2>EDITAR PERMISOS</h2>
          <ul class="actions">
            <li>
              <a href @click.prevent="$emit('eventToParent','canceled')">
                <i class="zmdi zmdi-close"></i>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
          <unc-loader></unc-loader>
        </div>
        <div v-else class="card-body card-padding">
          <form @submit.prevent="validateBeforeSubmit">
            <div class="fg-line">
                <label>Dependencia</label>
                <p>{{ dataParent.orgUnit.name }}</p>
            </div>
            <div class="form-group" :class="{'has-error': errors.has('permissions')}">
              <div class="fg-line">
                <label>Permisos</label>
              </div>
              <div v-for="permission of checkboxPermissions" :key="permission.code" class="checkbox m-b-30">
                <label>
                  <input 
                    type="checkbox"
                    name="permissions"
                    id="permissions"
                    v-model="item.permissions"
                    :value="permission.code"
                  />
                  <i class="input-helper"></i>
                  {{ permission.name }}
                </label>
              </div>
            </div>
  
            <div class="text-right">
              <div v-if="loadingAction" class="loading">
                <unc-loader></unc-loader>
              </div>
              <div v-else>
                <button
                  @click="$emit('eventToParent','canceled')"
                  type="button"
                  class="btn btn-link btn-sm m-t-10 waves-effect"
                >Atrás</button>
                <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect">Guardar cambios</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { HTTPFront, swal } from "../../share.js";
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    data() {
      return {
        isInitializing: false,
        item: {
          orgunit_id: "",
          permissions: []
        },
        checkboxPermissions: [],
        loadingAction: false,
      };
    },
    computed: {
      ...mapGetters(["person", "currentUser"])
    },
    props: ["dataParent"],
    mounted() {
      this.personSearch = this.person;
      this.item.permissions = this.dataParent.permissions;
      this.item.orgunit_id = this.dataParent.orgUnit._id;
      // INFO: Si la dependencia es UNC, dejo el permiso para cambiar permisos, si no, lo saco.
      this.checkboxPermissions = this.dataParent.orgUnit.code === "000" ? this.$store.getters.permissions : this.$store.getters.permissions.filter(p => p.code !== 'CHANGE_PERMISSIONS');
    },
    methods: {
      ...mapActions(['updateCurrentUser']),
      validateBeforeSubmit(e) {
        this.save();
      },
      async save() {
        let self = this;
        self.loadingAction = true;  
        let requestBody = {
          permissions: self.item.permissions,
        };
        const samePerson = self.person._id === self.currentUser.id[0];
        try {
          const response = await HTTPFront.put(`/orgUnits/${self.item.orgunit_id}/people/${self.personSearch._id}/permissions`, requestBody);
          if(response.status === 200) {
            await swal.fire({ 
              title: 'Éxito',
              text: `Los permisos se han modificado satisfactoriamente`,
              icon: "success",
            });
            let self = this;
            let lostPermission = false;
            if(samePerson) {
              lostPermission = !self.item.permissions.includes('CHANGE_PERMISSIONS');
              const currentAdminOrgUnits = [...self.currentUser.adminOrgUnits];
              const currentOu = currentAdminOrgUnits.find(ou => ou.orgUnit._id === self.item.orgunit_id);
              currentOu.permissions = self.item.permissions;
              this.updateCurrentUser({ adminOrgUnits: currentAdminOrgUnits });
            }
            self.$emit('eventToParent','saved', { lostPermission });
          }
        } catch (err) {
          let status = err.response.status;
          switch (status) {
            case 401:
              swal.fire({ title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
              break;
            case 409:
              swal.fire({ title: 'Algo salió mal', text: 'No se pudo modificar los permisos del administrador', icon: 'warning' });
              break;
            default:
              swal.fire({ title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
              break;
          }
        } finally {
          this.isInitializing = false;
          this.loadingAction = false;
        }
      },
    }
  };
  </script>
  
  <style lang="scss">
  </style>